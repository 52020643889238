import React, { useState, useEffect } from "react";
import "./style.scss";

// variables

// hooks

// funcs

// components

// styles

const Test = () => {
  const [aa, setAa] = useState(false);
  const [cateogries, setCategories] = useState();
  const sexyIdolCategoryId = 1;

  useEffect(() => {
    fetch(`https://loves-ux-xu.com/wp-json/wp/v2/categories?_embed`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setCategories(res.filter((item) => item.parent === sexyIdolCategoryId));
      });
  }, []);

  useEffect(() => {
    console.log(cateogries);
    if (cateogries) {
      fetch(
        `https://loves-ux-xu.com/wp-json/wp/v2/posts?per_page=100&categories=${cateogries[1].id}&_embed`
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
        });
    }
  }, [cateogries]);

  return (
    <div className="test">
      {cateogries &&
        cateogries.map((cateogry) => {
          return (
            <div key={cateogry.id}>
              {/* <img src={cateogry.custom_fields.img} alt="" /> */}
              <p>{cateogry.name}</p>
            </div>
          );
        })}
    </div>
  );
};

export default Test;
